import { Checkbox, FormControlLabel, FormGroup } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const ShowOwnListings = props => {
    const {
        intl
    } = props

    const [ownListingSelected, setOwnListingSelected] = useState(false)
    const [outOfStockSelected, setOutOfStockSelected] = useState(false)
    const queryString = typeof window !== 'undefined' && window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const history = useHistory()
    useEffect(() => {
        const searchType = urlParams?.get("searchType")
        const stock = urlParams?.get("stock")
        if (searchType == "ownListing") {
            setOwnListingSelected(true)
        }
        if (stock == "no-stock") {
            setOutOfStockSelected(true)
        }
    })

    const showOwnListingsCheckboxLabel = intl.formatMessage({ id: 'ShowOwnListings.showOwnListingsCheckboxLabel' });
    const showOutOfStockCheckboxLabel = intl.formatMessage({ id: 'ShowOwnListings.showOutOfStockCheckboxLabel' });

    return <div>
        <FormGroup>
            <FormControlLabel style={{ padding: 0 }} control={<Checkbox checked={ownListingSelected} onChange={(e) => {
                setOwnListingSelected(e.target.checked)
                if (e.target.checked) {
                    urlParams.append("searchType", "ownListing")

                } else {
                    urlParams.delete("searchType")
                }
                history.push(`/annonser?${urlParams.toString()}`)
            }} />} label={showOwnListingsCheckboxLabel} />
            <FormControlLabel style={{ padding: 0, paddingBottom: "0.5rem" }} control={<Checkbox checked={outOfStockSelected} onChange={(e) => {
                setOutOfStockSelected(e.target.checked)
                if (e.target.checked) {
                    urlParams.append("stock", "no-stock")

                } else {
                    urlParams.delete("stock")
                }
                history.push(`/annonser?${urlParams.toString()}`)
            }} />} label={showOutOfStockCheckboxLabel} />
        </FormGroup>
    </div>
}

export default ShowOwnListings