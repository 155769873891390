import React, { useContext, useState } from 'react';
import { Box, Divider } from '@mui/material';
import '../../styles/customMediaQueries.css';
import css from './RecommendedDimensionsCard.module.css';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useHistory } from 'react-router-dom';

export function RecommendedDimensionsCard(props) {
  const [activeIndex, setActiveIndex] = useState(null);
  const history = useHistory();

  const getOwnListingSearch = () => {
    const queryString = typeof window !== 'undefined' && window.location.search;
    const urlParamsPrevious = new URLSearchParams(queryString);
    const searchType = urlParamsPrevious?.get("searchType")
    const ownListingsMaybe = searchType == "ownListing" ? { searchType: "searchType=ownListing" } : {}
    return ownListingsMaybe
  }

  const handleWheelClick = (str, index) => {

    const ownListingsMaybe = getOwnListingSearch()

    let urlParams = {
      carBrandCategory: `pub_categoryLevel1=${str.toLowerCase()}`,
      productType: `pub_adType=wheel`,
      ...ownListingsMaybe,
    };

    const filtersUrl = Object.values(urlParams)
      .join('&')
      .replace(/\./g, '_')
      .replace(/-/g, 'n');

    history.push(`annonser?${filtersUrl}`);
    setActiveIndex(activeIndex === index ? null : index);
  };
  const handleTireClick = (str, index) => {
    const pattern = /(\d+)\/(\d+)(Z?R)(\d+)/;
    const match = str.match(pattern);

    if (match) {
      let urlParams = {};
      const ownListingsMaybe = getOwnListingSearch()

      urlParams = {
        tireWidthFront: `pub_tireWidthFront=${parseInt(match[1], 10)}`,
        // tireWidthRear: `pub_tireWidthRear=${parseInt(match[1], 10)}`,
        profileFront: `pub_profileFront=${parseInt(match[2], 10)}`,
        // profileRear: `pub_profileRear=${parseInt(match[2], 10)}`,
        diameter: `pub_diameter=${parseInt(match[4], 10)}`,
        productType: `pub_adType=tire`,
        ...ownListingsMaybe,
      };

      const filtersUrl = Object.values(urlParams)
        .join('&')
        .replace(/\./g, '_')
        .replace(/-/g, 'n');

      history.push(`annonser?${filtersUrl}`);
    } else {
      return { tireWidth: '', profile: '', diameter: '' };
    }
    setActiveIndex(activeIndex === index ? null : index);
  };
  const handleRimClick = (str, index) => {
    const numOfBolts = str[0];
    const boltCircle = str.slice(2, str.length);
    const ownListingsMaybe = getOwnListingSearch()

    let urlParams = {
      numOfBolts: `pub_numberOfBolts=${numOfBolts}`,
      boltCircle: `pub_boltCircle=${boltCircle}`,
      productType: `pub_adType=rim`,
      ...ownListingsMaybe,
    };

    const filtersUrl = Object.values(urlParams)
      .join('&')
      .replace(/\./g, '_')
      .replace(/-/g, 'n');

    history.push(`annonser?${filtersUrl}`);
    setActiveIndex(activeIndex === index ? null : index);
  };

  const Section = ({ title, items, activeIndex }) => (
    <Box
      component={'div'}
      sx={{
        padding: '0px 0px 10px 20px',
      }}
    >
      <h3
        style={{
          fontWeight: 'var(--fontWeightMedium)',
          fontSize: '21px',
          lineHeight: '33.6px',
          margin: '0px',
        }}
      >
        {title}
      </h3>
      {items.map((entry, index) => (
        <p
          key={index}
          onClick={() =>
            title === 'Hjul'
              ? handleWheelClick(entry, index)
              : title === 'Däck'
                ? handleTireClick(entry, index)
                : handleRimClick(entry, index)
          }
          className={`${css.arrayListItem} ${activeIndex === index ? css.clicked : ''}`}
        >
          {entry}
        </p>
      ))}
    </Box>
  );

  const List = props => {
    const data = {
      wheels: {
        title: 'Hjul',
        items: [
          'Audi',
          'BMW',
          'Ford',
          'Kia',
          'Mercedes',
          'Porsche',
          'Tesla',
          'Toyota',
          'Volkswagen',
          'Volvo',
        ],
      },
      tires: {
        title: 'Däck',
        items: [
          '195/65R15',
          '205/55R16',
          '205/60R16',
          '225/45R17',
          '225/50R17',
          '225/55R17',
          '225/40R18',
          '235/40R18',
          '235/35R19',
          '255/45R19',
        ],
      },
      rims: {
        title: 'Fälg',
        items: [
          '4/100',
          '5/100',
          '5/108',
          '5/110',
          '5/112',
          '5/114.3',
          '5/120',
          '5/130',
          '6/139.7',
          '8/165.1',
        ],
      },
    };

    return (
      <>
        {Object.keys(data).map(key => (
          <Section
            key={key}
            title={data[key].title}
            items={data[key].items}
            brand={props.brand}
            activeIndex={props.activeIndex}
          />
        ))}
      </>
    );
  };

  return (
    <Box
      component="section"
      width="100%"
      backgroundColor="white"
      borderRadius="10px"
      sx={{
        marginBottom: '20px',
        '@media (min-width: 430px)': {
          marginTop: '10px',
        },
      }}
    >
      <Accordion defaultExpanded={false}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel-content"
          id="panel-header"
        >
          <h2 className={css.carDetailHeading}>Populära dimensioner</h2>
        </AccordionSummary>
        <AccordionDetails>
          <Divider
            sx={{
              marginBottom: '10px',
              '@media (min-width: 767px)': {
                maxWidth: '40%',
              },
            }}
          />
          <Box
            sx={{
              '@media (min-width: 767px)': {
                display: 'grid',
                gridTemplateColumns: 'repeat(3, 1fr)',
              },
            }}
          >
            <List brand="Volvo" />
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
